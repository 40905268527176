<template>
  <input type="radio" class="btn-check" :name="name" :id="id" :value="modelValue" :disabled="disabled" @input="$emit('update:modelValue', $event.target.value)">
  <label :for="id" class="btn d-flex justify-content-center align-items-center" :class="_class" :style="{width: width, height: height}">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    modelValue: {
      required: true
    },
    disabled: Boolean,
    _class: String,
    width: String,
    height: String
  },
  emits: ['update:modelValue']
}
</script>

<style scoped>
  label {
    background: #AAABAB !important;
    font-size: 2rem;
  }

  input[type="radio"]:checked + label {
    border: 2px solid var(--dot-orange);
  }

  input[type="radio"]:hover:not(:checked) + label {
    opacity: 0.4;
  }

  input[type="radio"]:disabled + label {
    opacity: 0.2;
  }
</style>
